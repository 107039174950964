var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 512 512"
    }
  }, [_c('path', {
    attrs: {
      "d": "m301.776 48.36c-13.711 4.57-23.862 14.795-28.301 27.686h-34.951c-4.438-12.891-14.59-23.115-28.301-27.686-18.765-6.255-38.423-.059-50.215 15.674-25.151 33.53-39.008 75.087-39.008 117.011v30c0 6.885 4.688 12.876 11.367 14.546 40.43 10.122 82.031 15.176 123.633 15.176s83.203-5.054 123.633-15.176c6.68-1.67 11.367-7.661 11.367-14.546v-30c0-41.924-13.857-83.481-39.009-116.997-11.792-15.762-31.538-21.914-50.215-15.688z",
      "fill": _vm.color
    }
  }), _c('path', {
    attrs: {
      "d": "m421 391.045c0-8.291-6.709-15-15-15h-23.344c-10.406-17.847-29.542-30-51.656-30s-41.25 12.153-51.656 30h-46.688c-10.406-17.847-29.542-30-51.656-30s-41.25 12.153-51.656 30h-23.344c-8.291 0-15 6.709-15 15s6.709 15 15 15h15c0 33.091 26.909 60 60 60s60-26.909 60-60h30c0 33.091 26.909 60 60 60s60-26.909 60-60h15c8.291 0 15-6.709 15-15z",
      "fill": _vm.color
    }
  }), _c('path', {
    attrs: {
      "d": "m419.947 168.182c.253 4.301 1.053 8.549 1.053 12.863v30c0 20.684-14.019 38.628-34.087 43.652-42.598 10.664-86.646 16.069-130.913 16.069s-88.315-5.405-130.913-16.069c-20.068-5.024-34.087-22.968-34.087-43.652v-30c0-4.314.8-8.564 1.053-12.865-51.411 3.283-92.053 12.466-92.053 42.865 0 46.289 121.059 105 256 105s256-58.711 256-105c0-30.399-40.642-39.58-92.053-42.863z",
      "fill": _vm.color
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }