var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "agencyCreatorUserInvitePage"
  }, [_c('div', {
    style: {
      minHeight: "calc(100vh - ".concat(_vm.TOP_NAV_HEIGHT, "px)")
    },
    attrs: {
      "id": "inviteStack"
    }
  }, [_vm.acceptedInvite ? [_c('div', {
    attrs: {
      "id": "acceptedInvite"
    }
  }, [_c('CheckMarkCircledIcon', {
    attrs: {
      "color": "#1B1C21"
    }
  }), _c('span', {
    staticStyle: {
      "color": "#1B1C21"
    }
  }, [_vm._v("Invite Accepted")])], 1), _vm._m(0), _c('AppBadges')] : _vm.error ? _c('h4', {
    staticClass: "absolute center",
    attrs: {
      "id": "inviteCodeError"
    }
  }, [_vm._v(_vm._s(_vm.error))]) : !_vm.inviteCodeData ? _c('LoadingSpinner', {
    staticClass: "absolute center"
  }) : [_c('AgencyLogo', {
    attrs: {
      "onDark": "",
      "agencyLogo": _vm.inviteCodeData.agency.agencyLogo
    }
  }), _c('p', [_vm._v("Hey, "), _c('b', [_vm._v(_vm._s(_vm.inviteCodeData.creatorFullName || 'there'))]), _vm._v("!"), _c('br'), _vm._v("You've been invited to join "), _c('b', [_vm._v(_vm._s(_vm.inviteCodeData.agency.fullName || '[an agency]'))]), _vm._v(".")]), _c('div', {
    staticClass: "lightTheme",
    attrs: {
      "id": "floatingFooter"
    }
  }, [_c('QuickAuthFlow', {
    attrs: {
      "phoneTitle": "Sign In with your phone to accept the invite"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var authLoading = _ref.isLoading,
            isSignedIn = _ref.isSignedIn;
        return [_c('div', {
          attrs: {
            "id": "insideAuthFlow"
          }
        }, [!isSignedIn ? [authLoading ? [_c('LoadingSpinner')] : _vm._e()] : [_c('UserLine', {
          attrs: {
            "user": _vm.signedInUser,
            "disableAdminTooltip": "",
            "dynamicWidth": "",
            "onDark": ""
          }
        }), _c('PillButton', {
          attrs: {
            "squared": "",
            "large": "",
            "loading": _vm.acceptingInvite
          },
          on: {
            "click": _vm.acceptInvite
          }
        }, [_vm._v("Accept Invite")])]], 2)];
      }
    }])
  })], 1)]], 2)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v("Download the Reach app to get"), _c('br'), _vm._v("early access to paid opportunities:")]);
}]

export { render, staticRenderFns }