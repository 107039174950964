var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "quickAuthFlow"
  }, [_vm._t("default", null, null, _vm.currentState), _vm.currentState.step ? _c(_vm.currentState.step, {
    ref: "step",
    tag: "component",
    staticClass: "step",
    attrs: {
      "lightTheme": "",
      "title": _vm.currentState.title,
      "resendTimer": _vm.resendTimer
    },
    on: {
      "resendSms": _vm.resendSms,
      "startTimer": _vm.startResendCountdown
    }
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }