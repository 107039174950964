import { formatNumber, numberMagnitudeString, flattenObjectKeys } from '@happstv/shared/util/utils'
import { TIKTOK_NATIVE, INSTAGRAM_NATIVE, YOUTUBE_NATIVE, SNAPCHAT_NATIVE } from '@happstv/shared/util/multicastingConstants'
import { CAMPAIGN_SUBMITTABLE_TYPES, getExternalPlatformBySubmittableType } from '@happstv/shared/util/brands/brandCampaignUtils'
import { INSTAGRAM_SUBMITTABLE_PRODUCT_TYPES } from '@happstv/shared/constants/connectedAccounts/instagramConstants'

export const AGENCY_TYPE_LABELS = {
  singleRosterTalentAgency: 'Single-Roster Talent Agency',
  multiRosterTalentAgency: 'Multi-Roster Talent Agency',
  marketingAgency: 'Marketing Agency',
  brand: 'Brand',
  musicLabel: 'Music Label',
  musicArtist: 'Independent Musician',
}

export const getChartProps = (statList = [], seriesName, earningsKey, highlightColor = '#FFF') => {
  const data = statList.map(({ chartDate, stats }) => ({ x: chartDate, y: stats.managedEarnings?.[earningsKey] || 0 }))

  return {
    series: [
      { name: seriesName, data },
    ],
    options: {
      grid: {
        borderColor: '#FFFFFF1F',
        padding: {
          right: 50,
        },
      },
      chart: {
        animations: {
          enabled: false,
        },
        background: 'transparent',
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false,
          format: 'MMM \'yy',
        },
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          show: true,
          color: '#FFF',
        },
      },
      yaxis: [
        {
          title: {
            text: 'USD',
          },
          labels: {
            formatter: value => `$${numberMagnitudeString(value)}`,
          },
        },
      ],
      colors: [highlightColor],
      tooltip: {
        enabled: true,
        x: {
          format: 'MMMM yyyy',
        },
        y: [
          {
            formatter: value => `$${formatNumber(value)}`,
          },
        ],
      },
      theme: {
        mode: 'dark',
      },
      stroke: {
        width: [4],
        lineCap: ['round'],
      },
    },
  }
}

export const DELIVERABLE_TYPE_PLATFORM_LIST = [
  { key: undefined, label: 'Select a platform (optional)' },
  { key: TIKTOK_NATIVE, label: 'TikTok' },
  { key: INSTAGRAM_NATIVE, label: 'Instagram' },
  { key: YOUTUBE_NATIVE, label: 'YouTube' },
  { key: SNAPCHAT_NATIVE, label: 'Snapchat' },
]

export function getCampaignStatusInfo(campaign) {
  switch (campaign.campaignStatus) {
    case undefined: 
      break
    case 'inProgress': 
      return {
        label: 'In Progress',
        color: '#000',
        backgroundColor: '#FFF',
      }
    case 'pendingFinalization': 
      return {
        label: 'Wrapping Up',
        color: '#000',
        backgroundColor: '#f0c200',
      }
    case 'finalized': 
      return {
        label: 'Completed',
        color: '#FFF',
        backgroundColor: '#007e1f',
      }
    default:
      return {
        label: '[unknown status]',
        color: '#FFF',
        backgroundColor: '#494B58',
      }
  }

  if (campaign.finalResultsFinalized) {
    return {
      label: 'Completed',
      color: '#FFF',
      backgroundColor: '#007e1f',
    }
  }

  if (campaign.earningsEnabled) {
    return {
      label: 'In Progress',
      color: '#000',
      backgroundColor: '#FFF',
    }
  }

  if (campaign.earningsEnabledDate) {
    return {
      label: 'Wrapping Up',
      color: '#000',
      backgroundColor: '#f0c200',
    }
  }

  return {
    label: 'In Setup',
    color: '#FFF',
    backgroundColor: '#494B58',
  }
}

export function getCampaignCreateTypeInfo(campaign) {
  const { submissionType, submissionProductType } = campaign
  if (!submissionType) return undefined
  const platform = getExternalPlatformBySubmittableType(submissionType)

  let label
  switch (submissionType) {
    case CAMPAIGN_SUBMITTABLE_TYPES.TIKTOK_POST:
      label = 'TikTok Posts'
      break
    case CAMPAIGN_SUBMITTABLE_TYPES.INSTAGRAM_POST:
      switch (submissionProductType) {
        case INSTAGRAM_SUBMITTABLE_PRODUCT_TYPES.REELS:
          label = 'IG Reels'
          break
        case INSTAGRAM_SUBMITTABLE_PRODUCT_TYPES.IMAGE:
          label = 'IG Images'
          break
        case INSTAGRAM_SUBMITTABLE_PRODUCT_TYPES.CAROUSEL_ALBUM:
          label = 'IG Carousels'
          break
        case INSTAGRAM_SUBMITTABLE_PRODUCT_TYPES.FEED:
          label = 'IG Grid Posts'
          break
      }
      break
    case CAMPAIGN_SUBMITTABLE_TYPES.YOUTUBE_POST:
      label = 'YouTube Posts'
      break
  }

  if (!label) return undefined

  return { label, platform }
}


const validateExistingDemographic = (val) => {
  if (val === undefined || val === null) return false
  if (typeof val === 'object') return Object.keys(val).length
  if (typeof val === 'number') return val > 0
  return false
}

export function getAccountListFromMediaKit(mediaKit) {
  const {
    creatorConnectedAccounts = [],
    manuallyAddedAccounts = [],
    demographicsByAccountUeid = {},
    externalUserInfoByAccountUeid = {},
    hiddenConnectedAccountIds = [],
  } = mediaKit

  const dedupedAccountList = [...creatorConnectedAccounts, ...manuallyAddedAccounts]
    .filter(({ id }) => !hiddenConnectedAccountIds.includes(id))
    .filter(({ accountUeid }, i, a) => accountUeid && a.findIndex(another => another.accountUeid === accountUeid) === i)
    .sort((a, b) => a.accountUeid.localeCompare(b.accountUeid))

  return dedupedAccountList
    .map((account) => ({
      account,
      demographics: demographicsByAccountUeid[account.accountUeid],
      ...externalUserInfoByAccountUeid[account.accountUeid],
    }))
}

export function getDemographicsAccountListFromMediaKit(mediaKit) {
  const before = getAccountListFromMediaKit(mediaKit)
  const result = before
    .filter(({ demographics }) => demographics && Object.values(flattenObjectKeys(demographics)).every(validateExistingDemographic))
  return result
}

