//
//
//
//
//
//

export default {
  name: 'WarningIcon',
  props: {
    color: String,
  },
}
