//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

import performCloudFunction from '@/util/firebase/performCloudFunction'
import { TOP_NAV_HEIGHT } from '@/constants/app/appConstants'

import QuickAuthFlow from '@/components/auth/QuickAuthFlow.vue'
import AgencyLogo from '@/components/agent/AgencyLogo.vue'
import UserLine from '@/components/user/UserLine.vue'
import AppBadges from '@/components/app/AppBadges.vue'

import CheckMarkCircledIcon from '@/assets/svg/input/CheckMarkCircledIcon.vue'

export default {
  components: {
    QuickAuthFlow,
    AgencyLogo,
    UserLine,
    AppBadges,
    CheckMarkCircledIcon,
  },
  name: 'AgencyCreatorUserInvitePage',
  data() {
    return {
      TOP_NAV_HEIGHT,

      inviteCodeData: undefined,
      error: undefined,

      acceptingInvite: false,
      acceptedInvite: false,
    }
  },
  computed: {
    ...mapState([
      'signedInUser',
    ]),
    inviteCode() {
      return this.$route.params.inviteCode
    },
  },
  watch: {
    inviteCode: {
      immediate: true,
      async handler(inviteCode) {
        this.inviteCodeAgency = undefined
        this.inviteCodeError = undefined

        if (!inviteCode) return

        const inviteCodeResponse = await performCloudFunction('checkAgencyCreatorInviteCode', { inviteCode })
        if (!inviteCodeResponse.success || !inviteCodeResponse.agency) {
          if (inviteCodeResponse.signedInUserAlreadyAccepted) {
            this.acceptedInvite = true
            return
          }
          this.error = inviteCodeResponse.error || 'Failed to verify invite code.'
          return
        }

        this.inviteCodeData = inviteCodeResponse
      },
    },
  },
  methods: {
    async acceptInvite() {
      this.acceptingInvite = true

      const { inviteCode } = this

      const acceptCodeResponse = await performCloudFunction('connectUserWithAgencyCreator', { inviteCode })

      this.acceptingInvite = false

      if (!acceptCodeResponse.success) {
        this.error = acceptCodeResponse.error || 'Failed to accept invite code.'
        return
      }

      this.acceptedInvite = true
    },
  },
}
