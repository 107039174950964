//
//
//
//
//
//
//

import { mapState } from 'vuex'

import { ONE_SECOND } from '@happstv/shared/util/timeConstants'

const AuthPhoneStep = () => import('@/components/auth/steps/AuthPhoneStep.vue')
const AuthSmsCodeStep = () => import('@/components/auth/steps/AuthSmsCodeStep.vue')

const RESET_TIMER_SECONDS = 30

export default {
  name: 'QuickAuthFlow',
  props: {
    phoneTitle: String,
  },
  data() {
    return {
      resendTimer: 0,
    }
  },
  computed: {
    ...mapState([
      'signedInFirebaseUser',
      'signedInUser',
    ]),
    ...mapState('auth', [
      'smsConfirmation',
    ]),

    currentState() {
      const { signedInFirebaseUser, signedInUser, smsConfirmation, phoneTitle } = this

      if (!signedInFirebaseUser) {
        if (!smsConfirmation) {
          return {
            step: AuthPhoneStep,
            title: phoneTitle,
          }
        }

        return {
          step: AuthSmsCodeStep,
          back() { this.$store.commit('auth/SET_SMS_CONFIRMATION', undefined) },
        }
      }

      if (!signedInUser) {
        return { isLoading: true }
      }

      return { isSignedIn: true }
    },
  },
  methods: {
    async resendSms() {
      // resets back to phone step
      this.$store.commit('auth/SET_SMS_CONFIRMATION', undefined)

      await this.$nextTick()

      const { step } = this.$refs
      step?.sendSms?.()
    },

    startResendCountdown(secondsRemaining) {
      let secondsLeft = secondsRemaining || RESET_TIMER_SECONDS

      localStorage.setItem('resendTimerEndTime', Date.now() + secondsLeft * ONE_SECOND)

      const timerId = setInterval(countdown, 1000)
      const component = this

      function countdown() {
        if (secondsLeft <= 0) {
          clearTimeout(timerId)
          component.resendTimer = 0
          localStorage.removeItem('resendTimerEndTime')
        } else {
          component.resendTimer = secondsLeft
          secondsLeft--
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch('analytics/LOG_EVENT', { name: 'auth_flow_viewed' })
    const endTime = localStorage.getItem('resendTimerEndTime')
    const secondsRemaining = Math.floor(Math.max(0, endTime - Date.now()) / ONE_SECOND)
    if (secondsRemaining > 0) {
      this.startResendCountdown(secondsRemaining)
    }
  },
}
