//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { makeReachUrl } from '@happstv/shared/util/makeReachUrl'
import { makeFirestoreUrl } from '@happstv/shared/util/makeFirestoreUrl'
import { getDoc } from '@happstv/shared/util/firebase/firestoreUtils'
import { USER_DOC_PATH, BRAND_CAMPAIGN_USER_RELATIONSHIP_DOC_PATH } from '@happstv/shared/util/firebase/firestorePaths'

import UserProfilePhoto from '@/components/user/UserProfilePhoto.vue'

import PillButton from '@/components/universal/basic/PillButton.vue'
import Tooltip from '@/components/universal/overlapping/Tooltip.vue'
import LoadingSpinner from '@/components/universal/basic/LoadingSpinner.vue'

import OpenInNewWindowIcon from '@/assets/svg/admin/OpenInNewWindowIcon.vue'
import WarningIcon from '@/assets/svg/info/WarningIcon.vue'
import InvitesIcon from '@/assets/svg/studio/InvitesIcon.vue'
import NotesIcon from '@/assets/svg/indicators/NotesIcon.vue'
import VipShieldIcon from '@/assets/svg/user/VipShieldIcon.vue'
import AgentIcon from '@/assets/svg/admin/AgentIcon.vue'

const ConsoleAccountErrorDrawer = () => import('@/drawers/console/ConsoleAccountErrorDrawer.vue')

export default {
  name: 'UserLine',
  components: {
    UserProfilePhoto,
    PillButton,
    Tooltip,
    LoadingSpinner,
    OpenInNewWindowIcon,
    WarningIcon,
    InvitesIcon,
    NotesIcon,
    VipShieldIcon,
    AgentIcon,
  },
  props: {
    user: Object, // Note: use `resolvedUser` instead of this
    userId: String, // Use only if `user` isn't known to external component
    secondLineText: String,
    small: Boolean,
    onDark: Boolean,
    disableAdminTooltip: Boolean,
    disableSecondLine: {
      type: Boolean,
      default: false,
    },
    adminTooltipDirection: {
      type: String,
      default: 'up',
    },
    dynamicWidth: Boolean,
    adminTooltipBackgroundColor: String,
    adminToolsChannelId: String,
    channelId: String,
  },
  data() {
    return {
      ConsoleAccountErrorDrawer,
      makeReachUrl,
      makeFirestoreUrl,
      USER_DOC_PATH,
      BRAND_CAMPAIGN_USER_RELATIONSHIP_DOC_PATH,

      localUser: undefined,
      waitlistInviteUserData: undefined,
    }
  },
  computed: {
    resolvedUser() {
      const { user, localUser } = this
      return user || localUser
    },
    showAdminTooltip() {
      // go the long way here instead of using mapState, so that this still works on storybook
      if (!(((this.$store || {}).state || {}).signedInUser || {}).adminPermission) return false
      if (this.disableAdminTooltip) return false
      return true
    },
    resolvedSecondLineText() {
      if (this.small || this.disableSecondLine) return undefined
      return this.secondLineText
    },
    inviteUser() {
      const { waitlistInviteUserId } = this.resolvedUser
      if (!waitlistInviteUserId) return undefined

      const { username, fullName } = this.waitlistInviteUserData || {}

      return {
        id: waitlistInviteUserId,
        label: username ? `${fullName || '[unnamed]'} @${username}` : '[loading...]',
      }
    },
    banFlag() {
      const { bannedFromCommunity, bannedFromCommunityShadow, internalWarningFlag } = this.resolvedUser
      if (bannedFromCommunity) {
        return { color: '#FF1272', text: 'This user is banned.' }
      }
      if (bannedFromCommunityShadow) {
        return { color: '#FF1272', text: 'This user is shadow banned.' }
      }
      if (internalWarningFlag) {
        return { color: '#FF6A14', text: 'This user has a manual warning flag.' }
      }
      return undefined
    },
    inConsole() {
      return this.$route.matched.some(({ name }) => name === 'console')
    },
    internalNotes() { return (this.resolvedUser || {}).internalNotes },
    communityVip() { return (this.resolvedUser || {}).communityVip },
    defaultAgencyBonusParams() { return this.resolvedUser?.defaultAgencyBonusParams },
  },
  watch: {
    userId: {
      immediate: true,
      async handler(newValue) {
        this.localUser = undefined

        if (newValue) {
          const userDoc = await getDoc(USER_DOC_PATH(newValue))
          if (this.userId === newValue) this.localUser = { id: userDoc.id, ...(userDoc.data() || {}) }
        }
      },
    },
  },
  methods: {
    openErrorDrawer({ userId, isSingleAccount, isUserFacing, channelId }) {
      this.$store.dispatch('overlays/ADD_FULLSCREEN_POPUP', {
        component: ConsoleAccountErrorDrawer,
        popupType: 'leftDrawer',
        drawerWidth: '600px',
        params: {
          userId,
          isSingleAccount,
          isUserFacing,
          channelId,
        },
      })
    },
    async downloadMoreInfo() {
      const { waitlistInviteUserId } = this.resolvedUser || {}

      if (waitlistInviteUserId && waitlistInviteUserId !== this.waitlistInviteUserData?.id) {
        const userDoc = await getDoc(USER_DOC_PATH(waitlistInviteUserId))
        this.waitlistInviteUserData = { id: waitlistInviteUserId, ...(userDoc.data() || {}) }
      }
    },
  },
}
