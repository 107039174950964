
const AgencySelectPage = () => import('@/pages/agent/AgencySelectPage.vue')

const AgencyInvitePage = () => import('@/pages/agent/AgencyInvitePage.vue')

const AgentCampaignInvitePage = () => import('@/pages/agent/campaign/AgentCampaignInvitePage.vue')

const NewAgencyPage = () => import('@/pages/agent/NewAgencyPage.vue')

const AgencyPage = () => import('@/pages/agent/AgencyPage.vue')

const AgencyStatsPage = () => import('@/pages/agent/AgencyStatsPage.vue')

const AgencyTeamPage = () => import('@/pages/agent/AgencyTeamPage.vue')
const AgencyWalletPage = () => import('@/pages/agent/AgencyWalletPage.vue')
const AgentWalletPage = () => import('@/pages/agent/AgentWalletPage.vue')
const AgencyAgentPage = () => import('@/pages/agent/AgencyAgentPage.vue')
const AgencySettingsPage = () => import('@/pages/agent/AgencySettingsPage.vue')
const AgencyCreatorsPage = () => import('@/pages/agent/creator/AgencyCreatorsPage.vue')
const AgencyCreatorListsPage = () => import('@/pages/agent/creator/AgencyCreatorListsPage.vue')

const AgencyCampaignListPage = () => import('@/pages/agent/campaign/AgencyCampaignListPage.vue')
const AgencyCampaignPage = () => import('@/pages/agent/campaign/AgencyCampaignPage.vue')
const AgencyCampaignOverviewPage = () => import('@/pages/agent/campaign/AgencyCampaignOverviewPage.vue')
const AgencyCampaignPermissionsPage = () => import('@/pages/agent/campaign/AgencyCampaignPermissionsPage.vue')
const AgencyCampaignCreatesPage = () => import('@/pages/agent/campaign/AgencyCampaignCreatesPage.vue')
const AgencyCampaignYourCreatorsPage = () => import('@/pages/agent/campaign/AgencyCampaignYourCreatorsPage.vue')
const AgencyCampaignDashboardsPage = () => import('@/pages/agent/campaign/AgencyCampaignDashboardsPage.vue')

const AgencyCreatorPage = () => import('@/pages/agent/creator/AgencyCreatorPage.vue')
const AgencyCreatorOverviewPage = () => import('@/pages/agent/creator/AgencyCreatorOverviewPage.vue')
const AgencyCreatorMediaKitPage = () => import('@/pages/agent/creator/AgencyCreatorMediaKitPage.vue')
const AgencyCreatorCampaignsPage = () => import('@/pages/agent/creator/AgencyCreatorCampaignsPage.vue')

const AgencyCreatorCampaignRelationshipPage = () => import('@/pages/agent/creator/creatorCampaign/AgencyCreatorCampaignPage.vue')
const AgencyCreatorCampaignRelationshipOverviewPage = () => import('@/pages/agent/creator/creatorCampaign/AgencyCreatorCampaignOverviewPage.vue')
const AgencyCreatorCampaignRelationshipActivityPage = () => import('@/pages/agent/creator/creatorCampaign/AgencyCreatorCampaignActivityPage.vue')

const AgencyCreatorListPage = () => import('@/pages/agent/creator/AgencyCreatorListPage.vue')


function getAgencyCreatorCampaignRelationshipRoute(topRouteIsCampaign) {
  const campaignPathSegment = 'campaign/:brandCampaignId'
  const creatorPathSegment = 'creator/:creatorId'

  return {
    path: `${topRouteIsCampaign ? campaignPathSegment : creatorPathSegment}/${topRouteIsCampaign ? creatorPathSegment : campaignPathSegment}`,
    name: topRouteIsCampaign ? 'agencyCampaignCreator' : 'agencyCreatorCampaign',
    component: AgencyCreatorCampaignRelationshipPage,
    meta: {
      topRouteIsCampaign,
      navHighlightTabs: [topRouteIsCampaign ? 'agencyCampaigns' : 'agencyCreators'],
    },
    children: [
      {
        path: 'overview',
        name: topRouteIsCampaign ? 'agencyCampaignCreatorOverview' : 'agencyCreatorCampaignOverview',
        component: AgencyCreatorCampaignRelationshipOverviewPage,
        meta: {
          creatorCampaignNavTitle: 'Overview',
        },
      },
      {
        path: 'activity',
        name: topRouteIsCampaign ? 'agencyCampaignCreatorActivity' : 'agencyCreatorCampaignActivity',
        component: AgencyCreatorCampaignRelationshipActivityPage,
        meta: {
          creatorCampaignNavTitle: 'Activity',
        },
      },
    ],
  }
}


export default [
  {
    path: '',
    name: 'agentHome',
    component: AgencySelectPage,
    meta: {
      authRequired: true,
    },
  },
  {
    path: 'join/:inviteCode?',
    name: 'agencyInvite',
    component: AgencyInvitePage,
    meta: {
      authRequired: true,
    },
  },
  {
    path: 'create-org',
    name: 'newAgency',
    component: NewAgencyPage,
    meta: {
      authRequired: true,
    },
  },
  {
    path: 'campaign-collab/:inviteLinkId',
    name: 'agentCampaignInvite',
    component: AgentCampaignInvitePage,
    meta: {
      authRequired: true,
    },
  },
  {
    path: 'org/:agencyId',
    name: 'agency',
    component: AgencyPage,
    redirect: { name: 'agencyStats' },
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: 'overview',
        name: 'agencyStats',
        component: AgencyStatsPage,
        children: [
          { path: 'agency', name: 'agencyStatsAgency' },
          { path: 'agent', name: 'agencyStatsAgent' },
        ],
      },
      {
        path: 'team',
        name: 'agencyTeam',
        component: AgencyTeamPage,
      },
      {
        path: 'lists',
        name: 'agencyCreatorLists',
        component: AgencyCreatorListsPage,
        redirect: { name: 'agencyCreatorListsAgent' },
        children: [
          { path: 'all', name: 'agencyCreatorListsAgency' },
          { path: 'me', name: 'agencyCreatorListsAgent' },
        ],
      },
      {
        path: 'earnings/agency',
        name: 'agencyWallet',
        component: AgencyWalletPage,
      },
      {
        path: 'earnings/agent',
        name: 'agentWallet',
        component: AgentWalletPage,
      },
      {
        path: 'settings',
        name: 'agencySettings',
        component: AgencySettingsPage,
      },
      {
        path: 'creators',
        name: 'agencyCreators',
        component: AgencyCreatorsPage,
        redirect: { name: 'agencyCreatorsAgent' },
        children: [
          { path: 'all', name: 'agencyCreatorsAgency' },
          { path: 'me', name: 'agencyCreatorsAgent' },
        ],
      },
      {
        path: 'agent/:agentUserId',
        name: 'agencyAdminAgent',
        component: AgencyAgentPage,
        meta: {
          navHighlightTabs: ['agencyTeam'],
        },
      },
      {
        path: 'list/:creatorListId',
        name: 'agencyCreatorList',
        component: AgencyCreatorListPage,
        meta: {
          navHighlightTabs: ['agencyAdminCreatorLists'],
        },
      },
      {
        path: 'campaign/:brandCampaignId',
        name: 'agencyCampaign',
        component: AgencyCampaignPage,
        meta: {
          navHighlightTabs: ['agencyCampaigns'],
        },
        children: [
          {
            path: 'overview',
            name: 'agencyCampaignOverview',
            component: AgencyCampaignOverviewPage,
            meta: {
              campaignNavTitle: 'Overview',
            },
          },
          {
            path: 'permissions',
            name: 'agencyCampaignPermissions',
            component: AgencyCampaignPermissionsPage,
            meta: {
              campaignNavTitle: 'Permissions',
            },
          },
          {
            path: 'creates',
            name: 'agencyCampaignCreates',
            component: AgencyCampaignCreatesPage,
            meta: {
              campaignNavTitle: 'Creates',
            },
            redirect: { name: 'agencyCampaignAllCreates' },
            children: [
              {
                path: 'all',
                name: 'agencyCampaignAllCreates',
                meta: {
                  campaignCreatesTabTitle: 'All',
                },
              },
              {
                path: 'live',
                name: 'agencyCampaignLiveCreates',
                meta: {
                  campaignCreatesTabTitle: 'Live',
                },
              },
              {
                path: 'pending',
                name: 'agencyCampaignPendingCreates',
                meta: {
                  campaignCreatesTabTitle: 'Pending',
                },
              },
              {
                path: 'canceled',
                name: 'agencyCampaignCanceledCreates',
                meta: {
                  campaignCreatesTabTitle: 'Canceled',
                },
              },
            ],
          },
          {
            path: 'your-creators',
            name: 'agencyCampaignYourCreators',
            component: AgencyCampaignYourCreatorsPage,
            meta: {
              campaignNavTitle: 'Your Creators',
            },
          },
          {
            path: 'metrics',
            name: 'agencyCampaignMetrics',
            meta: {
              campaignNavTitle: 'Metrics',
            },
          },
          {
            path: 'dashboards',
            name: 'agencyCampaignDashboards',
            component: AgencyCampaignDashboardsPage,
            meta: {
              campaignNavTitle: 'Dashboards',
            },
          },
        ],
      },
      {
        path: 'campaigns',
        name: 'agencyCampaigns',
        component: AgencyCampaignListPage,
        redirect: { name: 'agencyActiveCampaigns' },
        children: [
          {
            path: 'active',
            name: 'agencyActiveCampaigns',
          },
          {
            path: 'archived',
            name: 'agencyArchivedCampaigns',
          },
          {
            path: 'opportunities',
            name: 'agencyMarketplaceCampaigns',
          },
        ],
      },
      {
        path: 'creator/:creatorId',
        name: 'agencyCreator',
        component: AgencyCreatorPage,
        meta: {
          navHighlightTabs: ['agencyCreators'],
        },
        redirect: { name: 'agencyCreatorOverview' },
        children: [
          {
            path: 'overview',
            name: 'agencyCreatorOverview',
            component: AgencyCreatorOverviewPage,
            meta: {
              creatorNavTitle: 'Overview',
            },
          },
          {
            path: 'media-kit',
            name: 'agencyCreatorMediaKit',
            component: AgencyCreatorMediaKitPage,
            meta: {
              creatorNavTitle: 'Media Kit',
            },
          },
          {
            path: 'campaigns',
            name: 'agencyCreatorCampaigns',
            component: AgencyCreatorCampaignsPage,
            meta: {
              creatorNavTitle: 'Campaigns',
            },
            redirect: { name: 'agencyCreatorAppliedCampaigns' },
            children: [
              {
                path: 'submittedApplications',
                name: 'agencyCreatorAppliedCampaigns',
              },
              {
                path: 'currentOffers',
                name: 'agencyCreatorUnappliedCampaigns',
              },
            ],
          },
        ],
      },
      getAgencyCreatorCampaignRelationshipRoute(true),
      getAgencyCreatorCampaignRelationshipRoute(false),
    ],
  },
]
